import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ ready, setReady ] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    //..
    
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [ ])

  if(ready) {
    return (
      <div 
        className="container"
      >
        <div className="row">
          <div className="col-md-12">
            <h6 className="main-section-title">
              Hello, admin
            </h6>
            
          </div>

          <div className="col-md-6">
            <div 
              className="card"
              onClick={() => {
                appContext.navTo({
                  item: 'branches'
                })
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="card-body">
                <div className="d-flex">
                  <PiBuildingOfficeBold
                    className="align-self-center mNoMargin text-secondary"
                    size={40}
                    style={{
                      flexShrink: "0",
                    }}
                  />
  
                  <div
                    className="align-self-center mNoMargin"
                    style={{
                      flexGrow: "1",
                      paddingLeft: "10px"
                    }}
                  >
                    <h6 style={{ fontSize: "14px" }} className="text-muted">Branches</h6>
                    <h6 
                      style={{ 
                        fontSize: "24px" ,
                      }} 
                      className="font-bold mOneLineText">
                      0
                    </h6>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
  
          <div className="col-md-6">
            <div 
              className="card"
              onClick={() => {
                appContext.navTo({
                  item: 'staff'
                })
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <div className="card-body">
                <div className="d-flex">
                  <SiGoogleclassroom
                    className="align-self-center mNoMargin text-secondary"
                    size={40}
                    style={{
                      flexShrink: "0",
                    }}
                  />
  
                  <div
                    className="align-self-center mNoMargin"
                    style={{
                      flexGrow: "1",
                      paddingLeft: "10px"
                    }}
                  >
                    <h6 style={{ fontSize: "14px" }} className="text-muted">Staff</h6>
                    <h6 
                      style={{ 
                        fontSize: "24px" ,
                      }} 
                      className="font-bold mOneLineText">
                      0
                    </h6>
                  </div>
  
                </div>
              </div>
            </div>
          </div>
  
  
          <div className="col-md-12">
            <div className="row">
  
              <div className="col-md-6">
                <div 
                  className="card"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                    })
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div className="card-body">
                    <h6 className="main-section-title font-semi-bold">
                      0 Tsh
                    </h6>
                    <h6
                      className="font-bold"
                      style={{
                        fontSize: "12px"
                      }}
                    >
                      Total Income
                    </h6>
                  </div>
                </div>
              </div>
  
              <div className="col-md-6">
                <div 
                  className="card"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                    })
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div className="card-body">
                    <h6 className="main-section-title font-semi-bold">
                      0 Tsh
                    </h6>
                    <h6
                      className="font-bold"
                      style={{
                        fontSize: "12px"
                      }}
                    >
                      Total Expenses
                    </h6>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div 
                  className="card"
                >
                  <div className="card-body">
                    <h6 className="main-section-title">Recent Tasks</h6>
                    <h6
                      className=""
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      No task was found
                    </h6>
                  </div>
                  
                </div>
              </div>
  
            </div>
          </div>

  
        </div>
  
      </div>
    )
  } else {
    return (
      <div 
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }

  
}