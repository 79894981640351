import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { formatDate, formatMoney } from "../Helpers";

export default function View({ data }) {

	const appContext = useContext(AppContext);

	/* Place states here */

	function view() {
		appContext.navTo({
			item: 'side-view',
			subItem: 'club-subscription-order-options',
			extraItem: data.id,
		})
	}

	return (
		<div className="One" onClick={view}>
			<div className="row">
				<div className="col-6 col-md-3">
					<h6
						style={{
							fontSize: "30px"
						}}
						className="font-light"
					>
						{data.subscriptionDuration || "N/A"}

						<span
							style={{
								display: "block",
								fontSize: "12px"
							}}
							className="font-regular"
						>
							Days
						</span>
					</h6>
				</div>

				<div className="col-6 col-md-3">
					<h6
						style={{
							fontSize: "30px"
						}}
						className="font-light"
					>
						{formatMoney(data.subscriptionPrice) || "N/A"}

						<span
							style={{
								display: "block",
								fontSize: "12px"
							}}
							className="font-regular"
						>
							Price in TZS
						</span>
					</h6>
				</div>

				<div className="col-6 col-md-3 mt-2">
					<h6
						style={{
							fontSize: "16px"
						}}
						className="font-semi-bold"
					>
						{data.payPhone || "N/A"}

						<span
							style={{
								display: "block",
								fontSize: "12px"
							}}
							className="font-regular"
						>
							Phone Number
						</span>
					</h6>
				</div>

				<div className="col-6 col-md-3 mt-2">
					<h6
						style={{
							fontSize: "16px"
						}}
						className="font-semi-bold"
					>
						{data.status}

						<span
							style={{
								display: "block",
								fontSize: "12px"
							}}
							className="font-regular"
						>
							Status
						</span>
					</h6>
				</div>

				<div className="col-12 text-start mt-4">
					<h6
						style={{
							fontSize: "12px"
						}}
						className="font-light"
					>
						<span
							style={{
								display: "block",
								fontSize: "12px"
							}}
							className="font-semi-bold text-success"
						>
							REF: {data.referenceNumber || "N/A"}
						</span>
						<span
							style={{
								display: "block",
								fontSize: "12px"
							}}
							className="font-semi-bold text-success"
						>
							VIA: {data.payWith || "N/A"}
						</span>

						{formatDate(data.orderDate)}

					</h6>
				</div>

			</div>
		</div>
	)

}