import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { arrayToString, callApi, CDN_URL, formatDate, formatMoney, stringToArray } from "../Helpers";
import { Editor } from '@tinymce/tinymce-react';
import { MdDelete } from "react-icons/md";

export default function View({ data, callback }) {

	const appContext = useContext(AppContext);
	const editorRef = useRef(null);


	/* Place states here */
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [cover, setCover] = useState(null);
	const [tags, setTags] = useState("");
	const [categories, setCategories] = useState([]);
	const [isPaid, setIsPaid] = useState("yes");
	const [loading, setLoading] = useState(false);
	const [opSuccessful, setOpSuccesful] = useState(false);
	const [isEditorReady, setIsEditorReady] = useState(false);

	function handleEditorChange(_content, _editor) {
		setContent(_content);

	};

	function addCategory() {
		setCategories(_prev => {
			const _new = [..._prev];
			_new.push("");

			return _new;
		})
	}

	function deleteCategory(_index) {
		setCategories(_prev => {
			const _new = [..._prev];
			_new.splice(_index, 1);

			return _new;
		})
	}

	function updateCategory(_index, _value) {
		setCategories(_prev => {
			const _new = [..._prev];
			_new[_index] = _value;

			return _new;
		})
	}

	function cleanCategories(_cats) {
		// Trim and filter empty items
		const _cleaned = _cats
			.map(item => item.trim()) // Trim whitespace
			.filter(item => item.length > 0); // Remove empty items

		// Use a Set to remove duplicates and convert back to an array
		return [...new Set(_cleaned)];
	}


	function getCover(e) {
		// Ensure the event has a file input with a file selected
		if (e.target && e.target.files && e.target.files[0]) {
			const file = e.target.files[0];

			// Use FileReader to read the file and generate a URL
			const reader = new FileReader();

			// On load, set the result (URL) to an image src or handle it as needed
			reader.onload = function (event) {
				const fileURL = event.target.result;
				setCover(fileURL); // Replace with your image handling logic
			};

			// Read the file as a Data URL
			reader.readAsDataURL(file);
		} else {
			//..
		}
	}

	// Function to handle image uploads
	async function imageUploadHandler(blobInfo) {

		return new Promise(async (resolve, reject) => {
			await callApi("upload-attachment", { file: blobInfo.blob() }).then(response => {
				if (response.status === 1) {
					resolve(CDN_URL + response.files[0]);
				} else {
					appContext.tellError("Could not upload attachment");
					reject("Could not upload attachment");
				}
			})
		})

	};

	// Function to handle file picker for images and media
	async function filePickerCallback(callback, value, meta) {
		const input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', meta.filetype === 'image' ? 'image/*' : 'video/*');

		input.onchange = async function () {
			const file = this.files[0];
			const formData = new FormData();
			formData.append('file', file);

			await callApi("upload-attachment", { file }).then(response => {
				if (response.status === 1) {
					callback(CDN_URL + response.files[0]);
				} else {
					appContext.tellError("Could not upload attachment");
				}
			})
		};

		input.click();
	};

	async function createArticle() {
		if (!loading) {
			const _cover = document.getElementById("_cover").files[0];

			if (title.trim().length > 0) {
				if (content.trim().length > 10) {
					if (_cover) {
						setLoading(true);
						await callApi('create-article', { cover: _cover, title, content, tags: JSON.stringify(stringToArray(tags)), isPaid, categories: JSON.stringify(cleanCategories(categories)) }).then((response) => {
							if (response.status === 1) {
								appContext.navBack();
								appContext.getAllArticles();
								appContext.tellMessage("Article was published successfully");
							} else {
								appContext.tellError(response.msg);
							}
						})
						setLoading(false);
					} else {
						appContext.tellError("Invalid cover image");
					}
				} else {
					appContext.tellError("Invalid content body");
				}
			} else {
				appContext.tellError("Invalid title");
			}
		} else {
			appContext.tellInfo("Please wait....");
		}
	}

	async function updateArticle() {
		if (!loading) {
			const _cover = document.getElementById("_cover").files[0];

			if (title.trim().length > 0) {
				if (content.trim().length > 10) {
					setLoading(true);
					await callApi('update-article', { id: data?.id, cover: (_cover) ? _cover : "", title, content, tags: JSON.stringify(stringToArray(tags)), isPaid, categories: JSON.stringify(cleanCategories(categories)) }).then((response) => {
						if (response.status === 1) {
							appContext.getAllArticles();
							appContext.tellMessage("Article was updated successfully");

							if (callback) {
								callback();
							}

						} else {
							appContext.tellError(response.msg);
						}
					})
					setLoading(false);
				} else {
					appContext.tellError("Invalid content body");
				}
			} else {
				appContext.tellError("Invalid title");
			}
		} else {
			appContext.tellInfo("Please wait....");
		}
	}

	useEffect(() => {
		if (data) {
			setTitle(data.title);
			setContent(data.content);
			setCover(CDN_URL + data.cover);
			setTags(arrayToString(JSON.parse(data.tags)));
			setCategories(JSON.parse(data.categories));
			setIsPaid(data.isPaid);

			if (editorRef.current && editorRef.current.editor) {
				editorRef.current.editor.setContent(data.content);
			}
		}
	}, [data, isEditorReady])

	useEffect(() => {
		appContext.setShowOverlayLoader(loading);
	}, [loading])

	return (
		<div
			className="container"
		>
			<div className="mFormType1">
				<div className="section-title">
					{data ? "Edit this Article" : "Create new article"}
				</div>

				<div className="body">
					<div className="mb-4">
						<label className="form-label">Title</label>
						<input
							className="form-control"
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</div>

					<div className="mb-4">
						<label className="form-label">Is Paid</label>
						<select
							className="form-control"
							type="text"
							value={isPaid}
							onChange={(e) => setIsPaid(e.target.value)}
						>
							<option value="yes">Yes</option>
							<option value="no">No</option>
						</select>
					</div>

					<div className="mb-4">
						<label className="form-label">Categories</label>
						<div
							style={{
								width: "100%",
								padding: "10px",
								border: "1px solid black",
								borderRadius: "10px",
							}}
						>
							{
								categories.map((item, i) => {
									return (
										<div className="d-flex mb-1" key={i}>
											<select
												key={i}
												className="form-control align-sel-center"
												value={item}
												onChange={(e) => {
													updateCategory(i, e.target.value)
												}}
												style={{
													flexGrow: "1",

													fontSize: "14px",
												}}
											>
												<option value="">Select category</option>
												{
													JSON.parse(appContext.systemParams.contentCategories).map((item, i) => {
														return (
															<option key={i} value={item.id}>{item.id}</option>
														)
													})
												}
											</select>

											<MdDelete
												size={24}
												style={{
													flexShrink: "0",
													cursor: "pointer",
												}}
												onClick={() => deleteCategory(i)}
												className="ml-2 text-danger align-self-center"
											/>
										</div>
									)
								})
							}
							<button
								className="btn btn-sm btn-dark"
								onClick={addCategory}
							>
								Add
							</button>
						</div>
					</div>

					<div className="mb-4">
						<label className="form-label">Tags</label>
						<input
							className="form-control"
							type="text"
							value={tags}
							onChange={(e) => setTags(e.target.value)}
						/>
						<div className="form-text">
							Separate tags with commas
						</div>
					</div>

					<div className="mb-4">
						<label className="form-label">Cover Photo </label>
						{
							(cover) ?
								<img
									width="100%"
									height="auto"
									src={cover}
								/>
								: ""
						}
						<input
							className="form-control"
							type="file"
							accept="image/*"
							id="_cover"
							onChange={(e) => getCover(e)}
						/>
					</div>

					<div className="mb-4">
						<label className="form-label">Body</label>
						<Editor
							onInit={(evt, editor) => {
								setIsEditorReady(true);
							}}
							tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6/tinymce.min.js"
							initialValue="<p></p>"
							ref={editorRef}
							init={{
								height: 500,
								menubar: false,
								branding: false,
								plugins: [
									'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
									'preview', 'anchor', 'searchreplace', 'visualblocks',
									'code', 'fullscreen', 'insertdatetime', 'media', 'table',
									'code', 'help', 'wordcount'
								],
								toolbar: 'undo redo | formatselect | bold italic backcolor | ' +
									'alignleft aligncenter alignright alignjustify | ' +
									'bullist numlist outdent indent | removeformat | image media | help',
								images_upload_handler: imageUploadHandler,
								file_picker_callback: filePickerCallback,
								image_title: true, // Enable the title field in the Image dialog
								automatic_uploads: true // Automatically upload images dropped or pasted into the editor
							}}
							onEditorChange={handleEditorChange}
						/>
					</div>

					<div className="mb-4">
						<button
							className="btn btn-padded bg-secondary btn-block"
							onClick={(data) ? updateArticle : createArticle}
						>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	)

}
