import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import OneClubSubscriptionOrder from '../ones/OneClubSubscriptionOrder';

export default function View(props) {

	const appContext = useContext(AppContext);

	/* Place states here */
	const [ready, setReady] = useState(false);
	const [statusFilter, setStatusFilter] = useState("all");

	const [ordersList, setOrdersList] = useState(null);
	const [pendingOrders, setPendingOrders] = useState(null)
	const [successOrders, setSuccessOrders] = useState(null);
	const [cancelledOrders, setCancelledOrder] = useState(null);
	const [searchFilter, setSearchFilter] = useState("");

	async function init() {
		//run initializations here
		setReady(false);
		await appContext.getAllClubSubscriptionOrders();
		setReady(true);
	}

	function processOrders() {
		if (appContext.allClubSubscriptionOrders && appContext.allClubSubscriptionOrders.length > 0) {
			const _pending = [];
			const _success = [];
			const _cancelled = [];

			for (const _order of appContext.allClubSubscriptionOrders) {
				if (_order.status === 'pending') {
					_pending.push(_order);
				} else if (_order.status === 'success') {
					_success.push(_order);
				} else if (_order.status === 'cancelled') {
					_cancelled.push(_order);
				}
			}

			setPendingOrders(_pending);
			setSuccessOrders(_success);
			setCancelledOrder(_cancelled);
			filterOrders();
		}
	}

	function filterOrders() {
		if (statusFilter) {
			if (statusFilter === 'all') {
				setOrdersList(appContext.allClubSubscriptionOrders);
			} else if (statusFilter === 'pending') {
				setOrdersList(pendingOrders);
			} else if (statusFilter === 'success') {
				setOrdersList(successOrders);
			} else if (statusFilter === 'cancelled') {
				setOrdersList(cancelledOrders);
			}
		} else {
			setOrdersList(appContext.allClubSubscriptionOrders);
		}
	}

	useEffect(() => {
		init();
	}, [])

	useEffect(() => {
		processOrders();
	}, [appContext.allClubSubscriptionOrders])

	useEffect(() => {
		filterOrders();
	}, [statusFilter])

	if (ready && appContext.allClubSubscriptionOrders) {
		return (
			<div
				className="container"
			>
				<div className="row">
					<div className="col-md-12">
						<h6 className="main-section-title">
							Subscription Orders
						</h6>

					</div>

					<div className="col-6 col-md-3">
						<div
							className="card"
							onClick={() => {
								setStatusFilter("all");
								document.getElementById('_coinsOrdersList').scrollIntoView({
									behavior: "smooth",
								})
							}}
							style={{
								cursor: "pointer",
							}}
						>
							<div className="card-body">
								<h6
									className="font-bold"
									style={{
										fontSize: "30px"
									}}
								>
									{formatMoney(appContext.allClubSubscriptionOrders?.length)}
								</h6>
								<h6
									className="font-semi-bold text-primary"
									style={{
										fontSize: "12px"
									}}
								>
									All
								</h6>
							</div>
						</div>
					</div>

					<div className="col-6 col-md-3">
						<div
							className="card"
							onClick={() => {
								setStatusFilter("success");
								document.getElementById('_coinsOrdersList').scrollIntoView({
									behavior: "smooth",
								})
							}}
							style={{
								cursor: "pointer",
							}}
						>
							<div className="card-body">
								<h6
									className="font-bold"
									style={{
										fontSize: "30px"
									}}
								>
									{formatMoney(successOrders?.length)}
								</h6>
								<h6
									className="font-semi-bold text-primary"
									style={{
										fontSize: "12px"
									}}
								>
									Successful
								</h6>
							</div>
						</div>
					</div>

					<div className="col-6 col-md-3">
						<div
							className="card"
							onClick={() => {
								setStatusFilter("pending");
								document.getElementById('_coinsOrdersList').scrollIntoView({
									behavior: "smooth",
								})
							}}
							style={{
								cursor: "pointer",
							}}
						>
							<div className="card-body">
								<h6
									className="font-bold"
									style={{
										fontSize: "30px"
									}}
								>
									{formatMoney(pendingOrders?.length)}
								</h6>
								<h6
									className="font-semi-bold text-primary"
									style={{
										fontSize: "12px"
									}}
								>
									Pending
								</h6>
							</div>
						</div>
					</div>

					<div className="col-6 col-md-3">
						<div
							className="card"
							onClick={() => {
								setStatusFilter("cancelled");
								document.getElementById('_coinsOrdersList').scrollIntoView({
									behavior: "smooth",
								})
							}}
							style={{
								cursor: "pointer",
							}}
						>
							<div className="card-body">
								<h6
									className="font-bold"
									style={{
										fontSize: "30px"
									}}
								>
									{formatMoney(cancelledOrders?.length)}
								</h6>
								<h6
									className="font-semi-bold text-primary"
									style={{
										fontSize: "12px"
									}}
								>
									Cancelled
								</h6>
							</div>
						</div>
					</div>



					<div className="col-md-12" id="_coinsOrdersList">
						<div
							className="card"
						>
							<div className="card-body">

								<div className="d-flex justify-content-between mb-4">

									<h6
										className="font-semi-bold text-primary mNoMargin align-self-center"
										style={{
											fontSize: "14px",
											textTransform: "uppercase",
										}}
									>
										{statusFilter}
									</h6>

									<input
										className="form-control mNoMargin align-self-center"
										style={{
											width: "150px"
										}}
										onChange={(e) => setSearchFilter(e.target.value)}
										placeholder="Filter by phone"
									/>

								</div>

								{
									(ordersList && ordersList.length > 0) ?
										ordersList.map((item, i) => {
											if (searchFilter) {
												if (String(item.payPhone).indexOf(String(searchFilter)) > -1) {
													return (
														<OneClubSubscriptionOrder data={item} key={i} />
													)
												} else {
													return "";
												}
											} else {
												return (
													<OneClubSubscriptionOrder data={item} key={i} />
												)
											}
										})
										:
										<h6
											className=""
											style={{
												fontSize: "14px",
											}}
										>
											No order was found
										</h6>
								}
							</div>

						</div>
					</div>

				</div>

			</div>
		)
	} else {
		return (
			<div
				className="container mSupportLoading"
			>
				{getInlineLoader()}
			</div>
		);
	}


}