import axios from 'axios';
import Dashboard from './main-views/Dashboard';
import Articles from './main-views/Articles';
import CreateArticle from './views/CreateArticle';
import ArticleOptions from './views/ArticleOptions';
import StoryOptions from './views/StoryOptions';
import CreateStory from './views/CreateStory';
import Stories from './main-views/Stories';
import SubscriptionOrderOptions from './views/SubscriptionOrderOptions';
import SubscriptionOrders from './main-views/SubscriptionOrders';
import ClubSubscriptionOrders from './main-views/ClubSubscriptionOrders';
import ClubSubscriptionOrderOptions from './views/ClubSubscriptionOrderOptions';


export const BASE_API = 'https://utajiri.mauzo.tz/area222/';
//export const BASE_API = 'https://utajiri.werenium.com/api/';

export const CDN_URL = 'https://utajiriapp.mauzo.tz/cdn/'
//export const CDN_URL = 'https://utajiriapp.werenium.com/cdn/'

export function getMainView(app) {
  if (app.navItem === 'dashboard') {
    return <Dashboard />
  } else if (app.navItem === 'articles' || !app.navItem) {
    return <Articles />;
  } else if (app.navItem === 'subscription-orders') {
    return <SubscriptionOrders />;
  } else if (app.navItem === 'club-subscription-orders') {
    return <ClubSubscriptionOrders />;
  }

  return null;
}

export function getViewerView(app) {
  if (
    app.navSubItem === 'create-article' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreateArticle />;
  } else if (
    app.navSubItem === 'create-story' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreateStory />;
  }

  return null;
}

export function getSideViewerView(app) {
  if (
    app.navSubItem === 'article-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ArticleOptions id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'story-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <StoryOptions id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'subscription-order-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return (
      <SubscriptionOrderOptions id={app.navExtraItem} />
    );
  } else if (
    app.navSubItem === 'club-subscription-order-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return (
      <ClubSubscriptionOrderOptions id={app.navExtraItem} />
    );
  }
}


export function getInlineLoader() {
  return (
    <div className="Loader"></div>
  )
}


export function formatDate(timestamp, fromPhp = true) {
  timestamp = (fromPhp) ? Number(timestamp) * 1000 : timestamp;
  let date = new Date(timestamp);
  return (date.toLocaleString());
}

export function callApi(url, params) {
  return new Promise(async resolve => {
    await getLocalUser().then(user => {
      //..
      //call main api
      let formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }

      for (let key in user) {
        formData.append(key, user[key]);
      }

      axios.post(BASE_API + url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {

        console.log(url, response.data);

        resolve(response.data);
      }).catch(error => {
        resolve({
          status: 0,
          msg: "Network Connection Error",
        })
      })
      //..
    })

  })
}

export function getLocalUser() {
  return new Promise(async resolve => {
    //use cookies
    let userId = getCookie('utajiri-adminId');
    let userToken = getCookie('utajiri-adminToken');
    let userType = getCookie('utajiri-adminType');

    resolve({
      userId,
      userToken,
      userType,
    })


  })
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function formatMoney(x) {
  //round x to 2 decimal places before og function
  x = Number(x);
  x = x.toFixed(0);

  //then continue
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function formatMoneyShorten(num, digits = 2) {
  num = Number.parseFloat(num);
  if (num <= 1 && num >= -1) {
    return num;
  }

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return Math.abs(num) >= item.value; // Use absolute value of num
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export function stringToArray(string) {
  const array = string.split(",").map(item => item.trim());
  return array;
}

export function arrayToString(arr) {
  return arr.join(', ');
}