import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import { MdSearch } from "react-icons/md";
import OneArticle from '../ones/OneArticle';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [publishedArticles, setPublishedArticles] = useState([]);
  const [unpublishedArticles, setUnpublishedArticles] = useState([]);
  const [articlesList, setArticlesList] = useState([]);
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState("published");


  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getAllArticles();
    setReady(true);
  }

  function groupArticles() {
    const _published = [];
    const _unpublished = [];

    if (appContext.allArticles && appContext.allArticles.length > 0) {
      for (const _article of appContext.allArticles) {

        if (_article.status === 'published') {
          _published.push(_article);
        } else if (_article.status === 'unpublished') {
          _unpublished.push(_article);
        }
      }
    }

    setPublishedArticles(_published);
    setUnpublishedArticles(_unpublished);

    if (status === 'published') {
      setArticlesList(_published);
    } else if (status === 'unpublished') {
      setArticlesList(_unpublished);
    }
  }


  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    groupArticles();

  }, [appContext.allArticles, status])



  if (ready) {
    return (
      <div
        className="container"
      >
        <div className="row">
          <div className="col-md-12">
            <h6 className="main-section-title">
              Articles
            </h6>
          </div>

          <div className="col-6">
            <div className="card">
              <div className="card-body">
                <h6
                  style={{
                    fontSize: "32px"
                  }}
                >
                  {formatMoneyShorten(publishedArticles.length)}
                  <span
                    style={{
                      display: "block",
                      fontSize: "12px",
                    }}
                  >
                    Published
                  </span>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="card">
              <div className="card-body">
                <h6
                  style={{
                    fontSize: "32px"
                  }}
                >
                  {formatMoneyShorten(unpublishedArticles.length)}
                  <span
                    style={{
                      display: "block",
                      fontSize: "12px",
                    }}
                  >
                    Unpublished
                  </span>
                </h6>
              </div>
            </div>
          </div>

          <div className="col-md-12 text-end">
            <button
              className="btn btn-primary btn-sm btn-rounded"
              onClick={() => {
                appContext.navTo({
                  item: 'view',
                  subItem: 'create-article',
                })
              }}
            >
              Create New
            </button>
          </div>

          <div className="col-md-12 mb-2 mt-4 pt-4" style={{ borderTop: "3px solid var(--surfaceColor)" }}>

            <div className="d-flex justify-content-between mb-4">
              <div
                className="d-flex align-self-center"
                style={{
                  width: "180px",
                  margin: 0,
                }}
              >
                <MdSearch size={24} style={{ flexShrink: "0" }} className="mNoMargin align-self-center" />
                <input
                  type="text"
                  className="form-control mNoMargin align-self-center"
                  style={{
                    flexGrow: "1",
                  }}
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>

              <div
                className="align-self-center"
                style={{
                  width: "120px",
                  margin: 0,
                }}
              >
                <select
                  type="text"
                  className="form-control mNoMargin font-semi-bold"
                  style={{
                    fontSize: "12px",
                  }}
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="published">Published</option>
                  <option value="unpublished">Unpublished</option>
                </select>
              </div>
            </div>

            {
              (articlesList && articlesList.length > 0) ?
                articlesList.map((item, i) => {
                  if (filter && filter.length > 0) {
                    if (String(item.title).toLowerCase().indexOf(String(filter).toLowerCase()) > -1) {
                      return <OneArticle key={i} data={item} />
                    } else {
                      return null;
                    }
                  } else {
                    return <OneArticle key={i} data={item} />
                  }

                })
                :
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                  className=""
                >
                  No articles were found
                </h6>
            }

          </div>


        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}