import { useContext, useEffect, useState, useRef } from "react"
import { AppContext } from "../App"
import { callApi, CDN_URL, formatDate, formatMoney } from "../Helpers";
import { Editor } from '@tinymce/tinymce-react';

export default function View({ }) {

  const appContext = useContext(AppContext);
	const editorRef = useRef(null);


  /* Place states here */
	const [ title, setTitle ] = useState("");
	const [ content, setContent ] = useState("");
	const [ cover, setCover ] = useState(null);
	const [ loading, setLoading ] = useState(false);
	const [ opSuccessful, setOpSuccesful ] = useState(false);

	function handleEditorChange(_content, _editor) {
    setContent(_content);

  };


	function getCover(e) {
		// Ensure the event has a file input with a file selected
		if (e.target && e.target.files && e.target.files[0]) {
			const file = e.target.files[0];

			// Use FileReader to read the file and generate a URL
			const reader = new FileReader();

			// On load, set the result (URL) to an image src or handle it as needed
			reader.onload = function(event) {
				const fileURL = event.target.result;
				setCover(fileURL); // Replace with your image handling logic
			};

			// Read the file as a Data URL
			reader.readAsDataURL(file);
		} else {
      //..
		}
	}

	// Function to handle image uploads
  async function imageUploadHandler(blobInfo) {

		return new Promise(async (resolve, reject) => {
			await callApi("upload-attachment", { file: blobInfo.blob() }).then(response => {
				if(response.status === 1) {
					resolve(CDN_URL+response.files[0]);
				} else {
					appContext.tellError("Could not upload attachment");
					reject("Could not upload attachment");
				}
			})
		})

  };

  // Function to handle file picker for images and media
  async function filePickerCallback (callback, value, meta) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', meta.filetype === 'image' ? 'image/*' : 'video/*');

    input.onchange = async function () {
      const file = this.files[0];
      const formData = new FormData();
      formData.append('file', file);

      await callApi("upload-attachment", { file }).then(response => {
				if(response.status === 1) {
					callback(response.files[0]);
				} else {
					appContext.tellError("Could not upload attachment");
				}
			})
    };

    input.click();
  };

  async function createStory() {
		if(!loading) {
			const _cover = document.getElementById("_cover").files[0];

			if(title.trim().length > 0) {
				if(content.trim().length > 10) {
					if(_cover) {
						setLoading(true);
						await callApi('create-story', { cover: _cover, title, content }).then((response) => {
							if(response.status === 1) {
								appContext.navBack();
								appContext.getAllStories();
								appContext.tellMessage("Story was published successfully");
							} else {
								appContext.tellError(response.msg);
							}
						})
						setLoading(false);
					} else {
						appContext.tellError("Invalid cover image");
					}
				} else {
					appContext.tellError("Invalid content body");
				}
			} else {
				appContext.tellError("Invalid title");
			}
		} else {
			appContext.tellInfo("Please wait....");
		}
  }

	useEffect(() => {
		appContext.setShowOverlayLoader(loading);
	}, [ loading ])

  return (
    <div
      className="container"
    >
			<div className="mFormType1">
				<div className="section-title">
					Create new story
				</div>

				<div className="body">
					<div className="mb-4">
						<label className="form-label">Title</label>
						<input
							className="form-control"
							type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
						/>
					</div>

					<div className="mb-4">
						<label className="form-label">Cover Photo </label>
            {
              (cover) ?
              <img
                width="100%"
                height="auto"
                src={cover}
              />
              :""
            }
						<input
							className="form-control"
							type="file"
							accept="image/*"
              id="_cover"
              onChange={(e) => getCover(e)}
						/>
					</div>

					<div className="mb-4">
						<label className="form-label">Body</label>
						<Editor
							tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6/tinymce.min.js"
							initialValue="<p>Write content here..</p>"
							init={{
								height: 500,
								menubar: false,
								plugins: [
									'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
									'preview', 'anchor', 'searchreplace', 'visualblocks',
									'code', 'fullscreen', 'insertdatetime', 'media', 'table',
									'code', 'help', 'wordcount'
								],
								toolbar: 'undo redo | formatselect | bold italic backcolor | ' +
												'alignleft aligncenter alignright alignjustify | ' +
												'bullist numlist outdent indent | removeformat | image media | help',
								images_upload_handler: imageUploadHandler,
								file_picker_callback: filePickerCallback,
								image_title: true, // Enable the title field in the Image dialog
								automatic_uploads: true // Automatically upload images dropped or pasted into the editor
							}}
							onEditorChange={handleEditorChange}
						/>
					</div>

          <div className="mb-4">
            <button
              className="btn btn-padded bg-secondary btn-block"
							onClick={createStory}
            >
              Publish
            </button>
          </div>
				</div>
			</div>
    </div>
  )

}
