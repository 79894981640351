import { useContext } from "react"
import { AppContext } from "../App"
import { MdLogout, MdMenu, MdNotifications, MdOutlineMessage, MdOutlineNotifications } from "react-icons/md";
import { IoNotificationsOutline, IoRefreshCircleOutline } from "react-icons/io5";
import { TbMessageDots } from "react-icons/tb";
import { callApi, setCookie } from "../Helpers";
import logo from '../assets/logo.png';

export default function TopBar(props) {

  const appContext = useContext(AppContext);

  async function logOut() {
    appContext.setShowOverlayLoader(true);
    await callApi("logout.php", {}).then(response => {
      //..
      setCookie('userId', "", -10000000);
      setCookie('userToken', "", -10000000);
      appContext.navTo({
        item: '',
      })
      appContext.refresh();
    })
    appContext.setShowOverlayLoader(false);
  }

  return (
    <div
      className="TopBar"
    >
      <div className="body mShadow3">
        <div
          className="container d-flex justify-content-between"
          style={{
            height: "100%",
          }}
        >
          <img
            src={logo}
            style={{
              width: "auto",
              height: "100%",
              margin: "0",
            }}
            className="align-self-center"
          />

          <div
            className="align-self-center mNoMargin mNoPadding d-flex"
            style={{
              height: "100%",
              width: "auto",
              flexShrink: "0"
            }}
          >

            <div
              className="mIcon"
              onClick={appContext.refresh}
            >
              <IoRefreshCircleOutline className="mSymbol" />
            </div>


            <div
              className="mIcon"
              onClick={logOut}
            >
              <MdLogout className="mSymbol" />
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}